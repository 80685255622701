import React, { FC } from 'react'
import ContactInfoBox from 'src/components/contact/ContactInfoBox'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface IEmailsBoxProps {
  className?: string
}

const EmailsBox: FC<IEmailsBoxProps> = ({ className }) => {
  const { t } = useTranslation('contact')
  // TODO Add translations for "Get in touch"
  return (
    <ContactInfoBox title={t('Get in touch')} className={className}>
      <div className="mb-2.5">
        <p className="font-bold"> {t('General Enquiries')}: </p>
        <a href="mailto:info@theifab.com">
          info@
          <wbr />
          theifab.com
        </a>
      </div>
      <div className="mb-2.5">
        <p className="font-bold"> {t('Media Enquiries')}: </p>
        <a href="mailto:media@theifab.com">
          media@
          <wbr />
          theifab.com
        </a>
      </div>
      <div className="mb-2.5">
        <p className="font-bold"> {t('Law Enquiries')}: </p>
        <a href="mailto:lawenquiries@theifab.com">
          lawenquiries@
          <wbr />
          theifab.com
        </a>
      </div>
      <p className="font-bold"> {t('Webshop Enquiries')}: </p>
      <a href="mailto:webshop@theifab.com">
        webshop@
        <wbr />
        theifab.com
      </a>
    </ContactInfoBox>
  )
}

export default EmailsBox
